import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
                    <p style={{ textAlign: "justify" }}>
            Howdy! My name is Alex, hailing from{" "}
            <span className="purple">Baton Rouge, Louisiana</span>. <p /> My
            passion for <span className="purple">hockey</span> led me on an
            exciting journey across various states. <p />I pursued my
            undergraduate studies in <span className="purple">Boston</span>, and
            later moved to <span className="purple">Texas</span> to complete my
            Master's degree. <p />
            Currently, my <span className="purple">
              Dog (Boston, "Bossy")
            </span>{" "}
            and I have made our home in the sunny state of{" "}
            <span className="purple">Florida</span>.{" "}
          </p>

          </p>
          <br />
          <p style={{ color: "rgb(200 229 255)" }}>
            "Nothing great in this world has ever been accomplished without
            passion."{" "}
          </p>
          <footer className="blockquote-footer">
            Georg Wilhelm Friedrich Hegel
          </footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
