import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/dogLogo.png";
import Tilt from "react-parallax-tilt";
import { AiFillGithub } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              ALLOW ME TO <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              From a young age, my natural{" "}
              <span className="purple emp">curiosity</span> led me to delve into
              the world of technology, where I began my journey by exploring and{" "}
              <span className="purple emp">hacking</span> into various systems.{" "}
              <p />
              This early fascination grew into a lifelong passion for
              understanding and creating through{" "}
              <span className="purple emp">code</span>.
              <p />
              <p>
                Currently, I'm using <span className="purple emp">React</span> &{" "}
                <span className="purple emp">Next.js</span> in my professional
                projects.
              </p>{" "}
              In my personal, I&apos;m exploring and creating with{" "}
              <span className="purple emp">Rust</span> &{" "}
              <span className="purple emp">Go </span>. <p />
              Powered with: <span className="purple empx">
                Arch Linux
              </span> & <span className="purple empx">NeoVim</span>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/operator-axel"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/alex-bolduc-p40/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
